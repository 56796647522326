<template>
  <div style="width:100%">
    <div class="headerBox">
      <div class="headerMain">
        <img src="../assets/newImg/headLogo.png" alt="" />
        <h3>Hi-Fin</h3>
        <p>fintech corp.</p>
      </div>
    </div>
    <!-- banner Box -->
    <div class="bannerBox">
      <div class="bigBox">
        <div class="titleText">
          <img src="../assets/newImg/texticon1.png" alt="" /> • Easy, safe, and
          convenient cash loan！
        </div>
        <div class="bannerMain">
          <div class="leftText">
            <h1>
              Hi-Fin provides convenient, professional, safe, reliable
              andhumanized
              <span>inclusive financial services</span> for the Filipino people.
            </h1>
            <p>
              Enjoy modern financial services through our products,
              whilebenefiting from your own credit value.
            </p>
            <!-- link Box -->
            <div class="linkBox">
              <a
                href="https://www.pesowallet-ph.com/"
                target="_blank"
                class="pesoBox"
              >
                <img src="../assets/newImg/pesoIcon.png" alt="" />
                <h5>PesoWallet</h5>
                <i class="el-icon-right"></i>
              </a>
              <a
                href="https://www.creditcash-ph.com/#/home"
                target="_blank"
                class="creditBox"
              >
                <img src="../assets/newImg/creditIcon.png" alt="" />
                <h5>Credit Cash</h5>
                <i class="el-icon-right"></i>
              </a>
            </div>
          </div>
          <img src="../assets/newImg/banner.png" alt="" />
        </div>
      </div>
    </div>
    <div class="main">
      <!-- platform advantages -->
      <div class="platformAdvantages">
        <div class="header">
          <div class="leftBox">
            <h5>Professional service</h5>
            <div>
              <img src="../assets/newImg/titleIcon.png" alt="" />
              • Platform Advantages
            </div>
          </div>
          <a class="rightText" href=""></a>
        </div>
        <!-- advantages list -->
        <ul>
          <li>
            <img src="../assets/newImg/advantages1.png" alt="" />
            <div>
              <h5>Service Advantages</h5>
              <p>Professional, scientific and all-around digital operation</p>
            </div>
          </li>
          <li>
            <img src="../assets/newImg/advantages2.png" alt="" />
            <div>
              <h5>Team Advantages</h5>
              <p>
                Mature and professional finance, operations and technical teams
              </p>
            </div>
          </li>
          <li>
            <img src="../assets/newImg/advantages3.png" alt="" />
            <div>
              <h5>Safe and Secure</h5>
              <p>
                Protect your information by using advanced encryption algorithms
              </p>
            </div>
          </li>
          <li>
            <img src="../assets/newImg/advantages4.png" alt="" />
            <div>
              <h5>Digital Risk Control System</h5>
              <p>
                Strong innovative technology capability, model for controlling
                digital risks
              </p>
            </div>
          </li>
        </ul>
      </div>

      <!-- company Vision -->
      <div class="companyVision">
        <div class="leftText">
          <h5>Our Mission</h5>
          <h1>Company Vision <img src="../assets/newImg/six.png" alt="" /></h1>
          <ul>
            <li>
              <img src="../assets/newImg/visionIcon1.png" alt="" />
              <p>Safety, integrity and professional services as anchors</p>
            </li>
            <li>
              <img src="../assets/newImg/visionIcon2.png" alt="" />
              <p>
                Professional, efficient, scientific and technological innovation
              </p>
            </li>
            <li>
              <img src="../assets/newImg/visionIcon3.png" alt="" />
              <p>
                Tech-driven financial services for Filipinos for precise
                financial credit provision
              </p>
            </li>
          </ul>
        </div>
        <img src="../assets/newImg/visionImg.png" alt="" />
      </div>

      <!-- operation team -->
      <div class="operationTeam">
        <h5>Operation Team</h5>
        <h2>
          <img src="../assets/newImg/teamIcon.png" alt="" />
          • Professional technical team services
        </h2>
        <p>
          Users with credit and demand will enjoy the convenience and pleasure
          of modern financial services through our products. At the same time,
          we help every person benefit from their own credit value.👍
        </p>
        <img class="teamBannerBox" src="../assets/newImg/teamImg.png" alt="" />

        <ul>
          <li>
            <img src="../assets/newImg/teamIcon1.png" alt="" />
            <div>
              <h2>Professional and mature financial team</h2>
              <p>
                We have excellent data actuaries and financial actuaries from
                different regions
              </p>
            </div>
          </li>
          <li>
            <img src="../assets/newImg/teamIcon2.png" alt="" />
            <div>
              <h2>Highly specialized technical team</h2>
              <p>
                Our team is committed to staying up-to-date with the latest
                technologies and ensuring the stability of our platform's
                operations
              </p>
            </div>
          </li>
          <li>
            <img src="../assets/newImg/teamIcon3.png" alt="" />
            <div>
              <h2>Excellent and keen operation team</h2>
              <p>
                Stay updated on local policies and regulations for prompt
                business adjustments.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- footer Box -->
    <div class="footerBox">
      <div class="leftFoot">
        <img src="../assets/newImg/headLogo.png" alt="" />
        <h3>Hi-Fin</h3>
        <p>fintech corp.</p>
      </div>
      <img src="../assets/newImg/footRightImg.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}
.headerBox {
  background: #ffffff;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 2px;
  .headerMain {
    display: flex;
    align-items: center;
    width: 1300px;
    margin: 0 auto;
    height: 76px;
    img {
      width: 41px;
      height: 41px;
    }
    h3 {
      font-family: Open Sans, Open Sans;
      font-weight: bold;
      font-size: 18px;
      color: #000000;
      margin: 0 10px;
    }
    p {
      font-family: Open Sans, Open Sans;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
    }
  }
}
.bannerBox {
  // width: 100%;
  padding-top: 42px;
  background-color: #f5f8ff;
  .bigBox {
    width: 1345px;
    margin: 0 auto;
    position: relative;
  }
  .titleText {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 331px;
    height: 36px;
    background: #e5edff;
    border-radius: 39px;
    font-family: Plus Jakarta Sans, Plus Jakarta Sans;
    font-weight: bold;
    font-size: 14px;
    color: #4f7df3;
    margin-bottom: 36px;
  }
  .bannerMain {
    display: flex;
    // justify-content: center;
    .leftText {
      position: relative;
      z-index: 10;
      padding-bottom: 64px;
      h1 {
        width: 611px;
        font-family: Open Sans, Open Sans;
        font-weight: 800;
        font-size: 40px;
        color: #181e4b;
        line-height: 68px;
        margin: 0 0 29px;
        span {
          color: #4475f2;
        }
      }
      p {
        font-family: Plus Jakarta Sans, Plus Jakarta Sans;
        font-weight: 500;
        font-size: 18px;
        color: #5e6282;
        margin-bottom: 50px;
        width: 477px;
      }

      .linkBox {
        display: flex;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 180px;
          height: 60px;
          box-shadow: 0px 20px 35px 0px rgba(68, 117, 242, 0.21);
          font-family: Plus Jakarta Sans, Plus Jakarta Sans;
          font-weight: 700;
          border: 1px solid #4475f2;
          font-size: 16px;
          h5 {
            margin: 0 10px;
          }
          img {
            width: 40px;
            height: 40px;
          }
        }
        .pesoBox {
          border-radius: 10px 0px 0px 10px;
          background: #ffffff;
          color: #181e4b;
        }
        .creditBox {
          box-shadow: 0px 20px 35px 0px rgba(68, 117, 242, 0.21);
          border-radius: 0px 10px 10px 0px;
          background: #4475f2;
          color: #fff;
        }
      }
    }
    & > img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 1059px;
      height: 650px;
      background-color: #f5f8ff;
    }
  }
}
.main {
  width: 1300px;
  margin: 0 auto;

  .platformAdvantages {
    padding: 0 46px;
    margin-top: 64px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 46px;
      .leftBox {
        h5 {
          font-family: Manrope, Manrope;
          font-weight: 800;
          font-size: 18px;
          color: #4475f2;
          margin-bottom: 14px;
        }
        div {
          display: flex;
          font-family: Manrope, Manrope;
          font-weight: 800;
          font-size: 36px;
          color: #14183e;
        }
      }
      a {
        font-family: Plus Jakarta Sans, Plus Jakarta Sans;
        font-weight: 600;
        font-size: 17px;
        color: #4475f2;
      }
    }

    ul {
      display: flex;
      li {
        position: relative;
        overflow: hidden;
        width: 278px;
        height: 370px;
        margin-right: 28px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.25);
        &:last-child {
          margin-right: 0;
        }
        div {
          position: absolute;
          z-index: 10;
          top: 258px;
          left: 17px;
          padding: 0 21px 0 0;
          h5 {
            font-family: Inter, Inter;
            font-weight: 700;
            font-size: 17px;
            color: #042521;
            margin: 0 0 10px;
          }
          p {
            font-family: Inter, Inter;
            font-weight: 400;
            font-size: 15px;
            color: #80918e;
          }
        }
      }
    }
  }

  .companyVision {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    .leftText {
      margin-right: 179px;
      h5 {
        margin: 0 0 10px;
        font-family: Manrope, Manrope;
        font-weight: 800;
        font-size: 18px;
        color: #4475f2;
      }
      h1 {
        display: flex;
        font-family: Plus Jakarta Sans, Plus Jakarta Sans;
        font-weight: 800;
        font-size: 50px;
        color: #14183e;
        margin: 0 0 75px 0;
      }
      ul {
        li {
          display: flex;
          font-family: Plus Jakarta Sans, Plus Jakarta Sans;
          font-weight: 400;
          font-size: 18px;
          color: #5e6282;
          margin-bottom: 68px;
          &:last-child {
            margin-bottom: 0;
          }
          p {
            width: 365px;
          }
          img {
            margin-right: 23px;
          }
        }
      }
    }
  }

  .operationTeam {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h5 {
      margin: 0;
      font-family: Manrope, Manrope;
      font-weight: 800;
      font-size: 18px;
      color: #4737ff;
    }
    h2 {
      display: flex;
      margin: 20px 0;
      font-family: Manrope, Manrope;
      font-weight: 800;
      font-size: 56px;
      color: #151d21;
      img {
        height: 62px;
      }
    }
    p {
      text-align: center;
      font-family: Manrope, Manrope;
      font-weight: 400;
      font-size: 18px;
      color: #18191f;
      line-height: 32px;
      width: 976px;
    }
    .teamBannerBox {
      margin: 40px 0 58px;
    }

    ul {
      display: flex;
      li {
        display: flex;
        margin-right: 61px;
        img {
          width: 49px;
          height: 51px;
          margin-right: 17px;
        }
        h2 {
          width: 265px;
          font-family: Manrope, Manrope;
          font-weight: 800;
          font-size: 22px;
          color: #18214d;
          line-height: 30px;
          margin: 0 0 10px;
        }
        p {
          width: 293px;
          font-family: Manrope, Manrope;
          font-weight: 500;
          font-size: 18px;
          color: #3e4462;
          line-height: 26px;
          text-align: left;
        }
        &:last-child {
          margin-right: 40px;
        }
      }
    }
  }
}

.footerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18.6%;
  height: 108px;
  background: #00195b;
  margin-top: 67px;
  .leftFoot {
    display: flex;
    align-items: center;
    img {
      width: 41px;
      height: 41px;
    }
    h3 {
      font-family: Open Sans, Open Sans;
      font-weight: bold;
      font-size: 18px;
      color: #fff;
      margin: 0 10px;
    }
    p {
      font-family: Open Sans, Open Sans;
      font-weight: 400;
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>
