import Vue from "vue";
import VueRouter from "vue-router";
// import inicio from "../views/inicio.vue";
import home from "../views/home.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  // {
  //   path: "/INICIO",
  //   name: "inicio",
  //   component: inicio,
  // },
  {
    path: "/home",
    name: "home",
    component: home,
  },
];
console.log(process.env);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
